import {Box, Typography} from "@mui/material";
import styles from '../../styles/Logo.module.scss'
import Link from "next/link";

export default function Logo() {
    return (
        <Box display={"flex"} flexDirection={"row"} alignSelf={"center"}>
            <Link href={'/'}>
                <img src='/images/logo.png' alt='ScreenStak™' className={styles.logo}/>
            </Link>
        </Box>
    )
}
