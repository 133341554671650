import styles from '../../styles/Footer.module.scss'
import PageContainer from "../layouts/PageContainer";
import {Box, Typography} from "@mui/material";
import Link from "next/link";
import companyInfo from "../../constants/address.json";
import Logo from "../shared/Logo";


export default function Footer() {
    return (<footer className={styles.footerContainer}>
        <Box display={"flex"} flexDirection={"column"}>
            <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                <PageContainer>
                    <Box className={styles.footerInnerContainer}>
                        <Box className={styles.footerItem}>
                            <Box className={styles.logo}>
                                <Logo/>
                            </Box>
                            <Box>
                                <Typography variant={"body1"}>{companyInfo["company"]}</Typography>
                                <Link target={"_blank"} href={"mailto:" + companyInfo["email"]}>{companyInfo["email"]}</Link>
                                <Typography variant={"body1"}>{new Date().getFullYear()} © All rights reserved.</Typography>
                            </Box>
                        </Box>
                        <Box className={styles.footerItemNav}>
                            <Box className={styles.footerItem}>
                                <Typography variant={"h6"}>Product</Typography>
                                <Link href={"/page/about-us"}>Features</Link>
                                <Link href={"/page/about-us"}>Pricing</Link>
                            </Box>
                            <Box className={styles.footerItem}>
                                <Typography variant={"h6"}>Resources</Typography>
                                <Link href={"/page/terms-of-service"}>Terms of Service</Link>
                                <Link href={"/page/privacy-policy"}>Privacy Policy</Link>
                            </Box>
                            <Box className={styles.footerItem}>
                                <Typography variant={"h6"}>Company</Typography>
                                <Link href={"/page/about-us"}>About Us</Link>
                                <Link href={"/page/about-us"}>Careers</Link>
                            </Box>
                            <Box className={styles.footerItem}>
                                <Typography variant={"h6"}>Support</Typography>
                                <Link href={"/page/about-us"}>Help Center</Link>
                            </Box>
                        </Box>
                    </Box>
                </PageContainer>
            </Box>
        </Box>
    </footer>)
}
