import React, {useState, useEffect} from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

const LoadingIndicator = ({visible}) => {
    const [opacity, setOpacity] = useState(visible ? 1 : 0);
    const [display, setDisplay] = useState(visible ? 'flex' : 'none');

    useEffect(() => {
        if (!visible) {
            setOpacity(0);
        }
    }, [visible]);

    const handleTransitionEnd = () => {
        if (!visible) {
            setDisplay('none');
        }
    };

    const containerStyle = {
        display: display,
        opacity: opacity,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 9999,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        transition: 'opacity 500ms',
    };

    return (
        <Box style={containerStyle} onTransitionEnd={handleTransitionEnd}>
            <Box sx={{width: "300px", textAlign: "center"}}>
                <img alt="InstaAlert Logo" src={"images/logo.png"} style={{marginBottom: "20px", width: "262px"}}/>
                <LinearProgress style={{width: '100%'}}/>
            </Box>
        </Box>
    );
};

export default LoadingIndicator;
