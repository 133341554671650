import {Box} from "@mui/material";
import styles from '../../styles/PageContainer.module.scss'

export default function PageContainer({children, overflow = 'hidden'}) {
    return (
        <Box className={styles.container} style={{overflow: overflow}}>
            {children}
        </Box>
    )
}
