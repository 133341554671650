import Footer from "../sections/Footer";
import NavigationBar from "../sections/NavigationBar";
import styles from "../../styles/MainLayout.module.scss";
import Box from "@mui/material/Box";

export default function MainLayout( {children}) {
    return (
        <div className={styles.mainLayout}>
            <NavigationBar />
            <Box flexGrow={1}>
                {children}
            </Box>
            <Footer/>
        </div>
    )
}
