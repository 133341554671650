import {createTheme} from '@mui/material/styles';

const lightTheme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: '100%',
                    fontSize: '1.5rem',
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '1.3rem',
                }
            }
        },

        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: '1.2rem',
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '18px !important',
                    textTransform: 'none',
                    fontFamily: 'GeneralSans-Regular',
                    borderRadius: "30px !important",
                    color: "#FFFFFF",
                    fontWeight: 500,
                    lineHeight: "25px",
                    padding: "16px 48px 16px 48px"
                }
            }
        },
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#3ABEF9',
        }
    },
    shape: {
        borderRadius: 10,
    },

    typography: {
        fontFamily: 'GeneralSans-Regular'
    },
});

export default lightTheme;
